$(document).ready(function(){
  $('.btn-submit').on("click", function(e){
    e.preventDefault()
  })
  $('.submit-auth').on("click", function(e){
    $("#oauth-form-new").submit()
  })
  $('.submit-signup').on("click", function(e){
    $("#new_user").submit()
  })
});