// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("jquery")
require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")
var intlTelInput = require("intl-tel-input")
var itiUtils = require("intl-tel-input/build/js/utils.js?1585994360633")
import 'bootstrap'
import 'packs/jquery.password'
import 'packs/jquery.tag-editor.min'
import "@fortawesome/fontawesome-free/js/all";
import "intl-tel-input/build/css/intlTelInput.css";
import "intl-tel-input/build/js/intlTelInput.js";
import "packs/oauth.js";
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

$(document).ready(function(){
  console.log("loaded")

  $('.strength-check #user_password').password({
    minLength: 8,
    allowSpace: true,
    doubleType: $('#user_password_confirmation'),
    personalInformation: [ $('#user_name') ],
    change: function(strength) {
      var password = $('#user_password').val(),
          score = strength + password.length,
          meter = $('.strength .meter');

      score = score / (100 + password.length) * 100;

      meter.css('width', score + '%');
      meter.attr('data-strength', Math.floor(score / 10));
    }
  });

  $("button.close, a.close").on("click", function(e){
    this.parentElement.remove()
  });

  var input = document.querySelector("#phone-number"),
  errorMsg = document.querySelector("#error-msg"),
  validMsg = document.querySelector("#valid-msg");

  if(input != null || input != undefined){
    // here, the index maps to the error code returned from getValidationError - see readme
    var errorMap = ["Invalid number", "Invalid country code", "Too short", "Too long", "Invalid number"];

    // initialise plugin
    var iti = intlTelInput(input,{
      hiddenInput: "user[phone]",
      utilsScript: itiUtils,
      initialCountry: "us"
    });

    var reset = function() {
      input.classList.remove("error");
      errorMsg.innerHTML = "";
      errorMsg.classList.add("hide");
      validMsg.classList.add("hide");
    };

    // on blur: validate
    input.addEventListener('blur', function() {
      reset();
      if (input.value.trim()) {
        if (iti.isValidNumber()) {
          validMsg.classList.remove("hide");
        } else {
          input.classList.add("error");
          var errorCode = iti.getValidationError();
          errorMsg.innerHTML = errorMap[errorCode];
          errorMsg.classList.remove("hide");
        }
      }
    });

    // on keyup / change flag: reset
    input.addEventListener('change', reset);
    input.addEventListener('keyup', reset);
  }

  $('.photo_upload').on('change', function(e) {
    return readURL(this);
  });
  function readURL(input) {
    var reader;
    if (input.files && input.files[0]) {
      reader = new FileReader();
    }
    reader.onload = function(e) {
      var $swap;
      $('.image_to_upload').attr('src', e.target.result).removeClass('hidden');
      $swap = $('.swap');
      if ($swap) {
        return $swap.removeClass('hidden');
      }
    };
    return reader.readAsDataURL(input.files[0]);
  };
})
